<template>
    <div style="text-align: left;font-size: 12px;">
        <el-card>
            <h2 class="text-center m-b-50">优惠券详情</h2>
            <div style="width:380px">
                <coupon :v="form"></coupon>
            </div>
            <el-form ref="form" :rules="rules" :model="form" label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="优惠券名称:" prop="ticketName">
                            <div>{{form.ticketName}}</div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="消费金额:" prop="ticketLimitFee">
                            <div>{{form.ticketLimitFee}}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="抵扣金额:" prop="ticketFee">
                            <div>{{form.ticketFee}}</div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="券库存量:" prop="ticketPubTotal">
                            <div>{{form.ticketPubTotal}}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="领券限制:" prop="ticketEachLimit">
                            <div>{{form.ticketEachLimit}}</div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="生效时间:" prop="ticketEffectiveTime">
                            <div>{{form.ticketEffectiveTime}}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="失效时间:" prop="ticketDeadTime">
                            <div>{{form.ticketDeadTime}}</div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <!-- <el-form-item label="使用说明:" prop="ticketDeadTime"> -->
                    <!-- <div>本券为<span class="c-10">xxxxxxxx</span>发放，仅限<span class="c-10">xxxx</span>店铺购买商品使用。</div> -->
                <!-- </el-form-item> -->
                <el-form-item label="适用范围:">
                    <div>{{['全店通用','店铺分类','指定商品'][form.ticketScope]}} 
                        <span class="f12 m-l-5 c-2" v-if="form.ticketScope==2">适用：{{form.furnitureSpuFullList && form.furnitureSpuFullList.length}}个商品</span>
                        <span class="f12 m-l-5 c-2" v-if="form.ticketScope==1">适用：{{form.furnitureClassifyFullList && form.furnitureClassifyFullList.length}}个店铺分类</span>
                    </div>
                    <div class="items" v-if="form.ticketScope==1">
                        <div v-for="(v,i) of form.furnitureClassifyFullList" class="categoryItem item f12 pointer select" :key="i">
                            <span>{{v.classifyName}}</span>
                            <img src="@/assets/images/stockCar/corner.png" class="corner">
                        </div>
                    </div>
                    <el-card shadow="never" style="margin-bottom: 25px;" v-if="form.ticketScope==2">

                        <div>
                            <template>
                                <el-table :data="form.furnitureSpuFullList" style="width: 100%" @row-click="rowClick">
                     
                                    <el-table-column type="index" label="编号">
                                    </el-table-column>
                                    <el-table-column prop="date" label="产品封面" width="100">
                                        <template slot-scope="scope">
                                            <el-avatar shape="square" :src="scope.row.cover" v-if="hasImportImg(scope.row.cover)"></el-avatar>
                                            <el-avatar shape="square" :src="$oucy.ossUrl+scope.row.cover" v-else></el-avatar>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="spuName" label="产品名称/所属品牌">
                                        <template slot-scope="scope">
                                            <div>{{scope.row.spuName}}</div>
                                            <div class="c-2" v-if="scope.row.brand && scope.row.brand.brandName">{{scope.row.brand.brandName}}</div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="brand.brandName" label="所属分类">
                                        <template slot-scope="scope">
                                            <span v-for="item in byLtUserAuthAndClassifyCategoryList" :key="item.id" v-if="scope.row.classify.id==item.id">{{item.classifyName}}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="spuPrise" label="当前价格">
                                        <template slot-scope="scope">
                                            <span class="spuPrise" v-if="scope.row.spuPrise=='0.00'">面议</span>
                                            <span class="spuPrise" v-else>￥{{scope.row.spuPrise}}</span>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </template>

                        </div>
                    </el-card>
                </el-form-item>
                <el-form-item>
                    <el-button @click="$oucy.back()">返回</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";
import { Notification } from "element-ui";
import { furnitureClassify, spu, ticket } from "@/service"
import { localSet, localGet, localDel } from "@/store/store"



export default {
    name: "detail",
    data() {

        return {
            rules: {

            },
            multipleSelection: [],
            prlductData: {},
            form: {
                enterpriseId: null,
                "ticketCreatetime": "",
                "ticketDeadTime": "",
                "ticketEachLimit": null,
                "ticketEffectiveTime": "",
                "ticketEnterprise": "",
                "ticketFee": null,
                "ticketGetTotal": 10,
                "ticketIsPublish": true,
                "ticketKind": 0,
                "ticketLimitFee": null,
                "ticketPubTotal": null,
                "ticketScope": 0,
                "ticketUpdatetime": ""
            },
            Kinds: [{
                value: 0,
                label: '满减券'
            }, {
                value: 1,
                label: '现金券'
            }, ],
            classify: 3,
            value1: null,
            color1: null,
            checked: null,
            imageUrl: '',
            // 选中列表
            select: {},
            classify: [],
            // 店内分类列表
            byLtUserAuthAndClassifyCategoryList: [],
            // 指定
            spuName: null,
            limit: 10,
            start: 0,
            classifyDatas: [],
            true_: true,
            false_: false,
            id: null,
        }
    },
    mounted() {
        let enterprise = localGet('enterprise')
        this.enterpriseId = enterprise.id
        this.id = this.$route.query.id

        // 查询
        this.getTicketById()

    },
    methods: {
        getTicketById() {
            ticket.getTicketById({ ticketId: this.id }).then(res => {
                this.form = res

            })
        },
        handleClick() {},

        // 选择触发
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },

        /**
         * 查询店内分类
         * @return {[type]} [description]
         */
        findByLtUserAuthAndClassifyCategory() {
            furnitureClassify.findByLtUserAuthAndClassifyCategory({ enterpriseId: this.enterpriseId }).then(res => {
                this.byLtUserAuthAndClassifyCategoryList = res
            })
        },
        // 选择分类
        setSelect(v) {
            let index = this.classify.indexOf(v.id)
            if (index > -1) {
                this.classify.splice(index, 1)
            } else {
                this.classify.push(v.id)
            }
            // this.select = v
        },
        rowClick(row, column, event) {
            this.setSput(row)
        },
        // 选择商品
        setSput(v) {
            let index = this.multipleSelection.indexOf(v.id)
            if (index > -1) {
                this.multipleSelection.splice(index, 1)
            } else {
                this.multipleSelection.push(v.id)
            }
            // this.select = v
        },

        hasImportImg(v) {
            return v.startsWith('//')
        },
        // 产品列表
        // 
        search() {
            // this.limit=10
            this.start = 0
            this.findAllFurnitureSpu()
        },
        sizeChange: function(limit) {
            const me = this;
            me.limit = limit;
            me.findAllFurnitureSpu();
        },
        currentChange: function(current) {
            const me = this;
            me.start = current - 1;
            me.findAllFurnitureSpu();
        },
        // 查询商品
        findAllFurnitureSpu() {
            // /spu/findAllFurnitureSpuClient
            spu.findAllFurnitureSpu({
                spuName: this.spuName,
                limit: this.limit,
                start: this.start,
                enterpriseId: this.enterpriseId
            }).then(res => {
                console.log(res)
                this.prlductData = res
            });
        },
        addCoupons() {
            ticket.addCoupons(this.form).then(res => {
                this.$message('创建成功')
                this.$oucy.go('/enter/CouponManagement')
                console.log(res)
            })
        },
    }

}
</script>
<style scoped lang="less">
.items {
    display: flex;
}

.item {
    padding: 4px 20px;
    line-height: 1.2;
    background: #FFFFFF;
    border: 1px solid #2090FF;
    border-radius: 2px;
    position: relative;
    border: 1px solid #EAEAEA;

    &.select {
        border: 1px solid #2090FF;

        .corner {
            display: inline-block;
        }
    }
}

.corner {
    position: absolute;
    right: 0;
    bottom: 0;
    display: none;
}

.categoryItem+.categoryItem {
    margin-left: 10px;
}
.c-10 {
    color: #2090FF;
}
</style>